
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'PrivacyPolicy',
  emits: ['title'],
  setup(_, { emit }) {
    const title = 'Hanwha Solutions Corporation - Privacy Policy';
    const techSupportEmail_EU = 'qhome.service@hanwha-qcells.com';
    const processorEmail_EU = 'q.home@e-service48.de';
    const webUrl = 'https://au.qommand.qcells.com';
    const hanwhaQcellsEmail = 'hanwha.qcells.adm@gmail.com';
    const qcellsUrl_AU = 'https://qcells.com/au/';
    const customerEmail_AU = 'customer@qcells.com.au';
    const techSupportEmail_AU = 'qhomewarranty@qcells.com.au';
    const processorEmail_AU = 'support@qhome.email';
    const commissionerUrl_AU = 'www.oaic.gov.au';
    emit('title', title);
    return {
      title,
      techSupportEmail_EU,
      processorEmail_EU,
      webUrl,
      hanwhaQcellsEmail,
      qcellsUrl_AU,
      customerEmail_AU,
      techSupportEmail_AU,
      processorEmail_AU,
      commissionerUrl_AU,
    };
  },
});
